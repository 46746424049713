<template>
  <div>
    <v-container fluid>
      <breadcrumbs :breadcrumbs="breadcrumbs"/>

      <v-row>
        <v-col cols="3">
          <BackofficeNavigation class="sticky-block"/>
        </v-col>

        <v-col cols="9">

          <v-card v-if="isLoading" class="rounded-lg shadow">
            <v-card-text>
              <v-skeleton-loader type="table"/>
            </v-card-text>
          </v-card>

          <v-card v-else class="rounded-lg shadow">


            <v-overlay :value="overlay"
                       absolute
                       :color="$vuetify.theme.dark ?'secondary':'white'">
              <v-progress-circular
                  indeterminate
                  size="60"
                  color="primary"
              ></v-progress-circular>
            </v-overlay>


            <v-card-title>

              <v-spacer/>

              <Refresh @refresh="getPendingInvestigations"/>

              <v-menu
                  transition="slide-x-transition"
                  bottom
                  right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs"
                         :disabled="! investigationSalepointIds.length"
                         class="transform-none blue--text"
                         v-on="on"
                         depressed>
                    Actions
                    <v-icon right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-list dense>

                  <v-list-item @click="validateAll">
                    <v-list-item-icon>
                      <v-icon color="success">mdi-check</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Valider</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="refuseAll">
                    <v-list-item-icon>
                      <v-icon color="error">mdi-close</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Réfuser</v-list-item-title>
                  </v-list-item>

                </v-list>
              </v-menu>


            </v-card-title>

            <v-divider/>

            <div v-if="tasks.length">

              <v-card-text class="pa-0">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>

                      <th>
                        <v-icon :color=" investigationSalepointIds.length > 0 ? 'blue' : ''"
                                class="pa-0 mt-0"
                                hide-details
                                @mousedown.prevent
                                @click="toggleAll">
                          {{ icon }}
                        </v-icon>
                      </th>

                      <th>Point de vente</th>
                      <th>Enquête</th>
                      <th>Gain</th>
                      <th>Date sync</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,i) in tasks"
                        :key="i"
                        :class=" investigationSalepointIds.includes(item.id) ? 'bg-snipper' : ''"
                    >

                      <td>
                        <v-checkbox hide-details
                                    multiple
                                    v-model=" investigationSalepointIds"
                                    :value="item.id"
                                    color="blue"
                                    class="pt-0 mt-0"></v-checkbox>
                      </td>

                      <td>
                       <span class="d-flex text-no-wrap cursor-pointer"
                             @click="showSalepoint(item.salepoint)">
                         {{ item.salepoint.name }}
                         <v-icon color="blue" small right>mdi-plus</v-icon>
                       </span>
                      </td>

                      <td>
                        {{ $func.setName(item.investigation.name, item.investigation.campaign.lang) }}
                      </td>

                      <td>
                        <v-chip small color="blue" dark>{{ item.investigation.amount | toCurrency }}</v-chip>
                      </td>

                      <td>
                        {{ item.syncronized_at }}
                      </td>

                      <td>
                        <v-chip small color="warning">En attente</v-chip>
                      </td>

                      <td>
                        <v-btn color="blue" icon
                               @click="showResponse(item)">
                          <v-icon>mdi-plus-circle</v-icon>
                        </v-btn>
                      </td>

                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>

              </v-card-text>

              <v-divider v-if="total > $totalPerPage"/>

              <v-card-actions v-if="total > $totalPerPage">
                <div class="w-100">
                  <v-select dense filled
                            hide-details
                            :items="$perPage"
                            v-model="perPage"></v-select>
                </div>
                <v-spacer/>
                <v-pagination total-visible="10"
                              circle
                              v-model="pagination.current"
                              :length="pagination.total"
                              @input="getPendingInvestigations"></v-pagination>
              </v-card-actions>

            </div>

            <v-card-text v-else>
              <NoResult size="200"/>
            </v-card-text>

          </v-card>

        </v-col>

      </v-row>


    </v-container>

    <SalepointDialog ref="salepointDialog"/>

    <RefuseDialog ref="refuseDialog"
                  @change-status="changeStatus($event)"/>

    <ConfirmDialog ref="confirmDialog"/>

    <ResponsesDialog :with-action="true"
                     @change-status="changeStatus($event)"
                     ref="responsesDialog"/>

  </div>
</template>

<script>

import BackofficeNavigation from "@/views/backoffice/components/BackofficeNavigation";
import RefuseDialog from "@/views/backoffice/tempValidation/components/RefuseDialog";
import ResponsesDialog from "@/views/backoffice/components/ResponsesDialog";
import {HTTP} from "@/http-common";

export default {
  components: {RefuseDialog, BackofficeNavigation, ResponsesDialog},
  data() {
    return {
      overlay: false,
      isLoading: false,
      tasks: [],
      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },

      investigationSalepointIds: []
    }
  },
  methods: {
    getPendingInvestigations() {
      this.$Progress.start()
      this.investigationSalepointIds = []
      this.isLoading = true
      HTTP.get('/backoffice/investigations/pending?page=' + this.pagination.current, {
        params: {
          perPage: this.perPage,
        }
      }).then(res => {
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;

        this.tasks = res.data.data.data
        this.$Progress.finish()
        this.isLoading = false
      }).catch(err => {
        this.$Progress.fail()
        this.$errorMessage = this.$i18n.t('message.catchError')
        this.isLoading = false
        console.log(err)
      })
    },
    showSalepoint(data) {
      this.$refs.salepointDialog.open(data)
    },
    toggleAll() {
      if (this.investigationSalepointIds.length === this.tasks.length) {
        this.investigationSalepointIds = []
      } else {
        this.investigationSalepointIds = this.tasks.map(el => el.id)
      }
    },

    async validateAll() {
      if (await this.$refs.confirmDialog.open()) {
        let data = {
          investigationSalepointIds: this.investigationSalepointIds,
          status: 'temp_validated',
        }
        this.changeStatus(data)
      }
    },

    changeStatus(data) {
      this.overlay = true
      HTTP.post('/backoffice/investigations/change-status', {
        investigationSalepointIds: data.investigationSalepointIds,
        status: data.status,
        raisonId: data.raisonId,
        remark: data.remark,
      }).then(() => {
        this.$Progress.finish()
        this.overlay = false
        this.$successMessage = this.$i18n.t('message.succssUpdate')
        this.getPendingInvestigations()
      }).catch(err => {
        this.$Progress.fail()
        this.$errorMessage = this.$i18n.t('message.catchError')
        this.overlay = false
        console.log(err)
      })
    },

    refuseAll() {
      this.$refs.refuseDialog.open(this.investigationSalepointIds)
    },
    showResponse(data) {
      this.$refs.responsesDialog.open(data)
    },

  },
  created() {
    this.getPendingInvestigations()
  },
  watch: {
    perPage() {
      this.getPendingInvestigations()
    }
  },
  computed: {
    breadcrumbs() {
      return [
        {to: '/#', text: 'breadcrumb.backoffice', disabled: true},
        {to: '/#', text: 'backoffice.tempValidation', disabled: true},
      ]
    },
    icon() {
      if (this.investigationSalepointIds.length === this.tasks.length) return 'mdi-close-box'
      if (this.investigationSalepointIds.length > 0 && this.investigationSalepointIds.length !== this.tasks.length) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  }
}
</script>

<style scoped>
</style>